<template>
  <v-container>
    <v-data-table
      dense
      :headers="headers"
      :items="answers"
      item-key="name"
      class="elevation-1"
    ></v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: 'TrickyQuestionAnswers',
  data: () => ({
    headers: [
      {
        text: 'Kluring (nummer)',
        align: 'start',
        value: 'trickyQuestionQId',
      },
      { text: 'Startposition', value: 't_start_position' },
      { text: 'Svar', value: 'answer' }
    ],
    answers: []
  }),
  created () {
    this.initialize()
  },
  methods: {
    ...mapActions(["getAllTrickyAnswers"]),
    async initialize () {
      const answers = await this.getAllTrickyAnswers()
      console.log(answers)

      this.answers = answers
    }
  }
}
</script>

<style>

</style>